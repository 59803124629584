import React from 'react';
import { Field } from 'react-final-form';
import RadioGroup from 'components/RadioButtons';

import * as S from './styled';

const ReportGrouping = () => (
  <>
    <S.Section>
      <S.SectionHeader>First List Tasks By:</S.SectionHeader>
      <S.SectionBody>
        <Field
          name={'groupBy'}
          component={RadioGroup}
          items={[
            { value: '0', name: 'Start with person who completed the tasks' },
            { value: '1', name: 'Start with manager responsible (multiple persons for Tasks)' },
          ]}
        />
      </S.SectionBody>
    </S.Section>
    <S.Section>
      <S.SectionHeader>Next List Tasks By:</S.SectionHeader>
      <S.SectionBody>
        <Field
          name={'subGroupBy'}
          component={RadioGroup}
          items={[
            { value: '0', name: 'Just sort all tasks by date and time (most recent first)' },
            { value: '1', name: 'First group the tasks per item they belong to (after Unit grouping)' },
          ]}
        />
      </S.SectionBody>
    </S.Section>
  </>
);

export default ReportGrouping;
