import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, sortByExecutionDateAndItemSequence } from './helpers';

import * as S from './styled';

const Item = ({ task }) => (
  <S.TaskList>
    <S.TaskHeader>{task.ItemName}</S.TaskHeader>
    <div>
      {task.items.map((item, i) => (
        <S.TaskRow key={i}>
          <S.TaskTitle styles={S.styles1}>{item.TaskType}</S.TaskTitle>
          <S.TaskInstruction styles={S.addStyles}>{item.OriginatingTaskName || 'Task Instruction'}</S.TaskInstruction>
          <S.TaskDate>{!item.CompletedOn ? item.ExecutionDate : item.CompletedOn}</S.TaskDate>
        </S.TaskRow>
      ))}
    </div>
  </S.TaskList>
);

Item.propTypes = {
  task: PropTypes.object.isRequired,
};

export const Table = ({ tasksList, category }) => {
  const groupedTasks = tasksList.reduce(
    (acc, task) => {
      if (task.Status === 'Completed') {
        acc.completed = [...acc.completed, task];
      }

      if (task.Status === 'Assigned' || task.Status === 'Unassigned' || task.Status === 'AttentionRequired') {
        acc.nonCompleted = [...acc.nonCompleted, task];
      }

      return acc;
    },
    { completed: [], nonCompleted: [] },
  );

  const filteredCompletedTasks =
    category === 'Quick Tasks'
      ? groupedTasks.completed.filter(item => item.TaskType === 'QuickTask')
      : groupedTasks.completed;

  const filteredNonCompletedTasks =
    category === 'Quick Tasks'
      ? groupedTasks.nonCompleted.filter(item => item.TaskType === 'QuickTask')
      : groupedTasks.nonCompleted;

  const sortedCompletedTasks = sortByExecutionDateAndItemSequence(filteredCompletedTasks);
  const sortedNonCompletedTasks = sortByExecutionDateAndItemSequence(filteredNonCompletedTasks);

  const completedTasksGroupedByItemName = groupBy(sortedCompletedTasks, 'ItemName');
  const nonCompletedTasksGroupedByItemName = groupBy(sortedNonCompletedTasks, 'ItemName');

  return (
    <>
      {!!filteredCompletedTasks.length && (
        <S.Container>
          <S.Name>{`${filteredCompletedTasks.length} Completed tasks`}</S.Name>
          {completedTasksGroupedByItemName.map((task, i) => (
            <Item key={i} task={task} />
          ))}
        </S.Container>
      )}
      {!!filteredNonCompletedTasks.length && (
        <S.Container>
          <S.Name>{`${filteredNonCompletedTasks.length} Non Completed tasks`}</S.Name>
          {nonCompletedTasksGroupedByItemName.map((task, i) => (
            <Item key={i} task={task} />
          ))}
        </S.Container>
      )}
    </>
  );
};

Table.propTypes = {
  tasksList: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
};
